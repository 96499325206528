<template>
    <div>
        <div class="header">
            <span class="header_txt">订单列表</span>
        </div>
        <div class="header_box">
            <div class="tip-title color">筛选条件</div>
            <div class="upper">
                <div style="margin: 20px 10px;">
                    <span class="upper_span">顾客信息</span>
                    <Input v-model="storePrams.keywords" type="text" placeholder="顾客名称/手机" style="width:150px"/>
                    <span class="upper_span">上级信息</span>
                    <Input v-model="storePrams.staff" type="text" placeholder="上级名称/手机" style="width:150px"/>
                    <span class="upper_span">项目名称</span>
                    <Input v-model="storePrams.project" type="text" placeholder="项目名称" style="width:150px"/>
                    <span class="upper_span">订单门店</span>
                    <Select v-model="storePrams.shop_id" style="width:150px" @on-open-change="shopSelect" @on-change="shopType">
                        <Option v-for="item in orderstore" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                    <span class="upper_span">订单状态</span>
                    <Select v-model="storePrams.status" style="width:150px">
                        <Option v-for="item in orderstate" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div>
                <div style="margin: 20px 10px;">
                    <span class="upper_span">订单类型</span>
                    <Select v-model="storePrams.type" style="width:150px" @on-change="activityType">
                        <Option v-for="item in ordertype" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                    <span class="upper_span">起始时间</span>
                    <DatePicker v-model="storePrams.start_time" type="datetime" placeholder="选择起始下单时间" style="width: 150px"></DatePicker>
                    <span class="upper_span">截止时间</span>
                    <DatePicker v-model="storePrams.end_time" type="datetime" placeholder="选择截止下单时间" style="width: 150px"></DatePicker>
                    <span class="upper_span">支付平台</span>
                    <Select v-model="paySelect" style="width:150px">
                        <Option v-for="item in payList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div>
            </div>
            <div class="below">
                <div class="left">
                    <span class="left_span">总订单数量：</span>
                    <span class="left_span" style="color: #f00;margin-right: 20px;">{{num}}</span>
                    <span class="left_span">总订单金额：</span>
                    <span class="left_span" style="color: #f00;">￥{{order_money}}</span>
                </div>
                <div class="right">
                    <div style="margin-bottom: 10px;">
                        <button class="header_btn" @click="toExport" style="margin-right: 10px;width: 120px;">连单礼(3单)</button>
                        <button class="header_btn" @click="teamwork" style="margin-right: 10px;">拼团与种草</button>
                        <button class="header_btn" @click="download" style="margin-right: 10px;width: 120px;">预约下载中心</button>
                        <button class="header_btn" @click="searchFor" style="margin-right: 10px;width: 70px;">搜索</button>
                    </div>
                    <div>
                        <button class="header_btn" @click="toTotalExport" style="margin-right: 10px;width: 120px;">今日数据汇总</button>
                        <button class="header_btn" @click="toTotalExport" style="margin-right: 10px;width: 120px;">订单数据汇总</button>
                        <button class="header_btn" @click="toExport" style="width: 120px;">下载导入结果</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable">
                <template slot-scope="{ row, index }" slot="niackname">
                    <div style="display: flex;">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:space-between;width: 140px;">
                            <img src="@/assets/head_man.png" alt="" style="width: 50px;border-radius:5px;">
                            <div>
                                <span>{{row.niackname}}</span>
                                <span>{{row.phone}}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row, index }" slot="project_json">
                    <div v-for="(item,index) in row.project_json" :key="index">
                        <span v-if="item.name">{{item.name}}</span>
                    </div>
                </template>
                <template slot-scope="{ row, index }" slot="superior">
                    <div style="display: flex;">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:space-between;width: 140px;">
                            <img src="@/assets/head_man.png" alt="" style="width: 50px;border-radius:5px;">
                            <span>{{row.superior}}</span>
                        </div>
                    </div>
                </template>
                <template slot-scope="{ row, index }" slot="z_number_status">
                    <div>
                        <span v-if="row.z_number_status == 0">未核销</span>
                        <span v-if="row.z_number_status == 1">已核销</span>
                    </div>
                </template>
                <template slot-scope="{ row, index }" slot="pay">
                    <span>微信</span>
                </template>
                <template slot-scope="{ row, index }" slot="type">
                    <span class="infoModal_text" @click="refund(row)">退款</span>
                    <span class="infoModal_text" @click="orderDetails(row)">订单详情</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 退款弹框 -->
        <Modal v-model="refundModal" title="退款申请" width="50%">
            <div>
                <Form ref="refundListForm" :model="refundListForm" :rules="refundListFormRules" label-position="left" :label-width="120">
                    <span class="left_span">交易信息</span>
                    <div style="padding: 0 20px;">
                        <FormItem label="交易单号:">
                            <span>{{refundListForm.order_no}}</span>
                        </FormItem>
                        <FormItem label="订单号:">
                            <span>{{refundListForm.order_no}}</span>
                        </FormItem>
                        <FormItem label="订单金额:">
                            <span>{{refundListForm.pay_money}}</span>
                        </FormItem>
                        <FormItem label="已申请退款金额:">
                            <span>{{refundListForm.refund_sum_money}}</span>
                            <span class="infoModal_text" @click="view">查看已申请退款单</span>
                        </FormItem>
                    </div>
                    <span class="left_span">退款信息</span>
                    <div style="padding: 0 20px;">
                        <FormItem label="退款方式">
                            <RadioGroup v-model="refundListForm.way">
                                <Radio label="原路返回"></Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label="退款金额">
                            <Input type="text" v-model="refundListForm.refundMoney" style="width: 100%;"></Input>
                            <span>(最多{{refundListForm.pay_money}}元)</span>
                        </FormItem>
                        <FormItem label="退款原因">
                            <Input type="textarea" maxlength="100" show-word-limit v-model="refundListForm.reason" style="width: 100%;"></Input>
                        </FormItem>
                        <FormItem label="退款密码">
                            <Input type="text" v-model="refundListForm.password" style="width: 100%;"></Input>
                        </FormItem>
                    </div>
                </Form>
            </div>
            <div slot="footer" style="display:flex;justify-content:center;">
                <Button type="primary" @click="refundModalT">确认退款</Button>
            </div>
        </Modal>
        <!-- 查看已申请退款单弹框 -->
        <Modal v-model="viewModal" title="已申请退款单" width="60%">
            <div style="margin-top: 20px;">
                <Table :columns="viewTable" :data="viewDataTable" :no-data-text="noDataText">
                    <template slot-scope="{ row, index }" slot="state">
                        成功
                    </template>
                </Table>
            </div>
            <div slot="footer"></div>
        </Modal>
        <!-- 拼团与种草弹框 -->
        <Modal v-model="teamworkModal" title="拼团与种草" width="50%">
            <div>
                <div>
                    <span class="upper_span">员工信息</span>
                    <Input type="text" placeholder="顾客名称/手机" style="width:150px;margin-right:10px;"/>
                    <span class="upper_span">门店信息</span>
                    <Select v-model="storeSelect" style="width:150px">
                        <Option v-for="item in orderstores" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </div>
                <div style="display:flex;justify-content:space-between;margin: 10px 0 20px 0;">
                    <div></div>
                    <div class="teamworkModal_right">
                        <Button type="primary" style="width:70px;margin-right: 10px;">搜索</Button>
                        <Button type="primary">下载导出</Button>
                    </div>
                </div>
                <Table :columns="teamworkTable" :data="teamworkDataTable" :no-data-text="noDataText">
                    <template slot-scope="{ row, index }" slot="">
                    </template>
                </Table>
            </div>
            <div class="footer">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer"></div>
        </Modal>
        <!-- 预约下载中心弹框 -->
        <Modal v-model="downloadModal" title="预约下载中心" width="50%">
            <div style="margin-top: 20px;">
                <Table :columns="downloadTable" :data="downloadDataTable" :no-data-text="noDataText">
                    <template slot-scope="{ row, index }" slot="type">
                        <span class="infoModal_text">下载</span>
                        <span class="infoModal_text" @click="deleteModal">删除</span>
                    </template>
                </Table>
            </div>
            <div slot="footer"></div>
        </Modal>
        <!-- 订单详情弹框 -->
        <Modal v-model="orderModal" title="订单详情" width="60%">
            <div>
                <span class="orderModal_span">下单用户信息</span>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">用户名</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.niackname}}</span>
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">账号</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.phone}}</span>
                </div>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">订单来源</span>
                    <span v-if="tabledetails.shop" style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.shop.name}}</span>
                    <span v-else style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">订单类别</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.active_text}}</span>
                </div>
                <span class="orderModal_span">实付价格</span>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">运费</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;">￥0</span>
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">实付</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;">￥{{tabledetails.pay_money}}</span>
                </div>
                <span class="orderModal_span">产品信息</span>
                <div v-for="item,index in tabledetails.project_json" :key="index">
                    <div style="width: 100%;display:flex;flex-direction:row;">
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">名称</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{item.name}}</span>
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">商品信息</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                    </div>
                    <div style="width: 100%;display:flex;flex-direction:row;">
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">价格</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{item.price}}</span>
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">数量</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{item.number}}</span>
                    </div>
                </div>
                <span class="orderModal_span">收获和物流信息</span>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">收货人</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">收货地址</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                </div>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">联系方式</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">快递方式</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                </div>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">快递公司</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">快递单号</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                </div>
                <div style="width: 100%;display:flex;flex-direction:row;">
                    <span style="padding: 10px;width: 20%;border:1px solid #ccc;">物流信息</span>
                    <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                </div>
                <span class="orderModal_span">订单信息</span>
                <div class="colModal">
                    <div style="width: 100%;display:flex;flex-direction:row;">
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">订单号</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.order_no}}</span>
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">下单时间</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.created_at}}</span>
                    </div>
                    <div style="width: 100%;display:flex;flex-direction:row;">
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">支付时间</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;">{{tabledetails.updated_at}}</span>
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">发货时间</span>
                        <span style="padding: 10px;width: 30%;border:1px solid #ccc;"></span>
                    </div>
                    <div style="width: 100%;display:flex;flex-direction:row;">
                        <span style="padding: 10px;width: 20%;border:1px solid #ccc;">订单状态</span>
                        <span v-if="tabledetails.z_number_status == 0" style="padding: 10px;width: 30%;border:1px solid #ccc;">未核销</span>
                        <span v-if="tabledetails.z_number_status == 1" style="padding: 10px;width: 30%;border:1px solid #ccc;">已核销</span>
                    </div>
                </div>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
import {orderList, shopSelect, toTotalExport, toExport, refund, refundList} from '@/api/index'
    export default {
        data() {
            return {
                orderstore: [],
                orderstores: [],
                storeSelect:'0',
                orderstate: [
                    {value: '0',label: '未核销'},
                    {value: '1',label: '已核销'}
                ],
                stateSelect:'0',
                ordertype: [
                    {value: '0',label: '所有'},
                    {value: '1',label: '限时特价'},
                    {value: '2',label: '秒杀活动'},
                    {value: '3',label: '种草活动'},
                    {value: '4',label: '活动预约'},
                    {value: '5',label: '拼团活动'},
                    {value: '6',label: '定金预售'}
                ],
                payList: [
                    {value: '0',label: '全部'},
                    {value: '1',label: '微信'},
                    {value: '2',label: '抖音'},
                ],
                paySelect:'0',
                //table
                liveTable: [
                    {title: '顾客',slot: 'niackname',align:'center'},
                    {title: '项目',slot: 'project_json',align:'center'},
                    {title: '支付金额',key: 'money',align:'center'},
                    {title: '上级',slot: 'superior',align:'center'},
                    {title: '订单状态',slot: 'z_number_status',align:'center'},
                    {title: '订单类型',key: 'active_text',align:'center'},
                    {title: '支付平台',slot: 'pay',align:'center'},
                    {title: '下单时间',key: 'created_at',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                dataTable: [],
                //page
                total: 10,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keywords:'',
                    type:'0',
                    shop_id: '0',
                    staff:'',
                    project:'',
                    start_time:'',
                    end_time:'',
                    status:'',
                },
                refundModal:false,
                refundListForm:{
                    order_no:'',
                    id:'',
                    pay_money:'',
                    refund_sum_money:'',
                    way:'',
                    refundMoney:'',
                    reason:'',
                    password:'',
                },
                // (验证弹框)
                //添加弹框-表单验证
                refundListFormRules: {
                    refundMoney: [
                        { required: true, message: '退款金额不能小于0', trigger: 'blur' }
                    ],
                    password: [
                        { required: true, message: '请输入退款密码', trigger: 'blur' }
                    ],
                },
                viewModal:false,
                noDataText:'暂无数据',
                viewTable: [
                    {title: '交易单号',key: 'order_no',align:'center'},
                    {title: '退款单号',key: 'refund_no',align:'center'},
                    {title: '退款金额',key: 'money',align:'center'},
                    {title: '退款状态',slot: 'state',align:'center'},
                    {title: '提交时间',key: 'created_at',align:'center'},
                ],
                viewDataTable: [],
                teamworkModal:false,
                totalModal: 10,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                },
                teamworkTable: [
                    {title: '店铺名',key: 'shopName',align:'center'},
                    {title: '员工姓名',key: 'name',align:'center'},
                    {title: '员工手机',key: 'phone',align:'center'},
                    {title: '已拼成',key: 'assembled',align:'center'},
                    {title: '未拼成',key: 'notPiecedTogether',align:'center'},
                    {title: '已种成',key: 'planted',align:'center'},
                    {title: '未种成',key: 'unspecified',align:'center'},
                ],
                teamworkDataTable: [
                    {shopName:'LOL(一店)',name:'经理',phone:'12345678901',assembled:'0',notPiecedTogether:'0',planted:'0',unspecified:'10',},
                    {shopName:'LOL(二店)',name:'经理',phone:'12345678901',assembled:'0',notPiecedTogether:'0',planted:'0',unspecified:'0',},
                    {shopName:'LOL(三店)',name:'经理',phone:'12345678901',assembled:'0',notPiecedTogether:'0',planted:'0',unspecified:'0',},
                ],
                downloadModal:false,
                downloadTable: [
                    {title: '申请时间',key: 'time',align:'center'},
                    {title: '文件名',key: 'name',align:'center'},
                    {title: '操作',slot: 'type',align:'center'},
                ],
                downloadDataTable: [
                    {time:'2023-12-23 15:00:00',name:'2023/12/23/2023-12-2312345.xls',},
                    {time:'2023-12-20 16:00:00',name:'2023/12/20/2023-12-2012345.xls',},
                    {time:'2022-11-11 15:34:12',name:'2022/11/11/2022-11-1112345.xls',},
                ],
                orderModal:false,
                //表格
                tabledetails:{
                    shop: {}
                },
                num:'',
                order_money:''
            }
        },
        mounted(){
            this.orderList()
        },
        methods: {
            // 导出订单
            toExport(){
                toExport(this.storePrams).then(res => {
                    window.open(res.data)
                })
            },
            // 订单数据汇总
            toTotalExport(){
                toTotalExport().then(res => {
                    this.$Message.success(res.msg);
                })
            },
            activityType(e){
                this.storePrams.type = e
                this.orderList()
            },
            shopType(e){
                this.storePrams.shop_id = e
                this.orderList()
            },
            //下拉店铺数据 
            shopSelect(){
                shopSelect().then(res => {
                    this.orderstore = res.data
                })
            },
            searchFor(){
                this.orderList()
            },
            // 订单列表
            orderList(){
                orderList(this.storePrams).then(res => {
                    console.log('订单列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                    this.num = res.data.num
                    this.order_money = res.data.order_money
                })
            },
            addActivity(){
                console.log('添加')                
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.orderList()
            },
            refund(item){
                console.log('退款', item)
                this.refundListForm = item
                this.refundModal = true
            },
            refundModalT(){
                this.refundModal = false
                let data = {
                    id: this.refundListForm.id,
                    reason: this.refundListForm.reason,
                    money: this.refundListForm.refundMoney
                }
                refund(data).then(res => {
                    this.$Message.success(res.msg);
                })
                .catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            //查看
            view(){
                this.viewModal = true
                refundList({id: this.refundListForm.id}).then(res => {
                    console.log('退款列表', res.data)
                    this.viewDataTable = res.data
                })
            },
            //拼团与种草
            teamwork(){
                this.teamworkModal = true
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            //预约下载中心
            download(){
                this.downloadModal = true
            },
            deleteModal(){
                this.$Message.success({
                    background: true,
                    content: '删除成功',
                    closable: true
                });
            },
            //订单详情
            orderDetails(item){
                console.log('item',item)
                this.orderModal = true
                this.tabledetails = item
                this.tabledetails.shop = item.shop
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color:  #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.header_box{
    margin: 10px 0;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.upper{
}
.below{
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.left{
}
.right{
}
.tip-title {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.tip-title:before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 20px;
  margin-right: 14px;
  background:  #409eff;
  border-radius: 4px;
}
.content{
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.upper_span{
    width: 80px;
    text-align: center;
    color: #666;
    font-weight: 700;
    margin: 0 10px;
}
.left_span{
    font-size: 16px;
    color: #000;
    font-weight: 700;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
.colModal{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 20px 0 10px 0;
}
.box {
    width: 100%;
    height: 40px;
    display: flex;
    border-left: 1px solid #e9e9e9;
    border-top: 1px solid #e9e9e9;
}
.content1 {
    width: 35%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-left: 10px;
    background-color: #fafafa;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    color: #909399;
    font-size: 14px;
}
.content2 {
    width: 65%;
    height: 40px;
    line-height: 40px;
    text-align: left;
    padding-left: 10px;
    background-color: #fff;
    border-right: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    color: #606266;
    font-size: 14px;
}
.orderModal_span{
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0;
    display: block;
}
</style>